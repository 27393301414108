var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-toolbar", {
    attrs: { searchable: "", search: _vm.searchText },
    on: {
      "update:search": function ($event) {
        _vm.searchText = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "toolbar-left",
        fn: function () {
          return [
            _c(
              "v-col",
              { staticClass: "px-0", attrs: { md: "2", sm: "3" } },
              [
                _c("v-text-field", {
                  attrs: {
                    outlined: "",
                    dense: "",
                    clearable: "",
                    "single-line": "",
                    "hide-details": "",
                    "prepend-inner-icon": "fal fa-search",
                    label: _vm.$t("searchLabel"),
                  },
                  model: {
                    value: _vm.searchText,
                    callback: function ($$v) {
                      _vm.searchText = $$v
                    },
                    expression: "searchText",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "2" } },
              [
                _c("v-autocomplete", {
                  attrs: {
                    items: _vm.classesAndBanks,
                    outlined: "",
                    dense: "",
                    flat: "",
                    solo: "",
                    "hide-details": "auto",
                    "aria-label": _vm.$t("classLabel"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function (data) {
                        return [
                          typeof data.item.value === "string" &&
                          data.item.value.startsWith("separator")
                            ? _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-divider", { staticClass: "mt-5" }),
                                      _c(
                                        "v-subheader",
                                        { staticClass: "justify-center" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(data.item.text) + " "
                                          ),
                                        ]
                                      ),
                                      _c("v-divider", { staticClass: "mb-5" }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c("v-list-item-content", [
                                _vm._v(" " + _vm._s(data.item.text) + " "),
                              ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.currentSubjectId,
                    callback: function ($$v) {
                      _vm.currentSubjectId = $$v
                    },
                    expression: "currentSubjectId",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "toolbar-right",
        fn: function () {
          return [
            !_vm.editMode
              ? _c(
                  "pb-btn",
                  {
                    attrs: {
                      color: "primary",
                      label: _vm.$t("addLabel") + " " + _vm.$t("unitLabel"),
                      elevation: "0",
                      text: "",
                      outlined: "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.editUnit()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("fal fa-plus")])],
                  1
                )
              : [
                  _vm.hasSelectedRows
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "ml-2 my-1",
                          attrs: {
                            color: "error",
                            label: _vm.$t("deleteLabel"),
                            text: "",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.deleteUnits.apply(null, arguments)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "pb-btn",
                    {
                      staticClass: "ml-2 my-1",
                      attrs: {
                        color: "primary",
                        label: _vm.$t("doneLabel"),
                        text: "",
                        outlined: "",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleEditMode.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "done-icon" }, [
                        _vm._v("fal fa-pen-slash"),
                      ]),
                    ],
                    1
                  ),
                ],
            !_vm.editMode
              ? _c(
                  "v-menu",
                  {
                    attrs: { bottom: "", "offset-y": "", "close-on-click": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on: onMenu }) {
                            return [
                              _c(
                                "pb-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-2 my-1",
                                    attrs: {
                                      color: "primary",
                                      label: _vm.$t("actionsLabel"),
                                      text: "",
                                      outlined: "",
                                    },
                                  },
                                  { ...onMenu }
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("fal fa-ellipsis-stroke-vertical"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      648777904
                    ),
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          { on: { click: _vm.toggleEditMode } },
                          [
                            _c(
                              "v-btn",
                              { attrs: { text: "", color: "primary" } },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-pen-to-square"),
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("manageLabel"))),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }