
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import UnitsMixin from '@/mixins/units-mixin';
import { Component } from 'vue-property-decorator';

@Component({
  mixins: [UnitsMixin],
  components: {
    BaseToolbar
  }
})
export default class UnitsToolbar extends BaseToolbar {
  searchText!: string;
  editMode!: boolean;
  hasSelectedRows!: boolean;

  get localUnitsComponent() {
    return this.currentPageInstance || {};
  }

  editUnit() {
    return this.localUnitsComponent.editUnit({});
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  deleteUnits() {
    return this.localUnitsComponent.deleteUnits();
  }

  get currentSubjectId() {
    return this.localUnitsComponent.currentSubjectId || 0;
  }

  set currentSubjectId(val:number) {
    this.localUnitsComponent.currentSubjectId = val;
  }

  get classesAndBanks():any[] {
    return this.localUnitsComponent.classesAndBanks || [];
  }
}
